<!--  -->
<template>
  <div class="page-login">
    <div class="wrap">
      <div class="wrap_left">
        <h2>Welcome Back !</h2>
        <p>
          Welcome back to Classnovo. Please login before continue.
        </p>
        <div class="img">
          <img :src="require('@/assets/images/forget_pwd_bg.png')" alt="" />
        </div>
      </div>
      <div class="wrap_right">
        <div class="title">Log In</div>
        <div class="form">
          <template v-for="item in fromData">
            <gg-input
              :key="item.key"
              :ref="item.key"
              v-model="item.value"
              filedType="input"
              :type="item.type"
              :placeholder="item.placeholder"
              :error="errorObj[item.key]"
              @keyup="validate(item.key)"
            />
          </template>

          <div class="forgot">
            <router-link :to="{ path: '/forgotPwd',query:{ email: fromData[0].value } }"
              >Forgot password</router-link
            >
          </div>
          <div class="bottom">
            <div class="remember">
              <md-checkbox v-model="checkboxVal" class="md-primary"
                >Remember me</md-checkbox
              >
            </div>
            <div class="submit" v-loading="loading" @click="handleLogin">
              Login
            </div>
          </div>
        </div>
      </div>
    </div>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns/esm/fp";
import { Auth } from "@/common";
export default {
  data() {
    return {
      query: {
        account: "",
        loginType: "0",
        password: "",
        verificationCode: "string",
      },
      fromData: [
        {
          type: "input",
          value: "",
          placeholder: "Email address",
          key: "formLogin",
          reg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          errorText: "Invalid email address",
        },
        {
          type: "password",
          value: "",
          placeholder: "Password",
          key: "formPassword",
          reg: /\S/,
          errorText: "Password required",
        },
      ],
      errorObj: {
        formLogin: {
          show: false,
          message: "Email address required",
        },
        formPassword: {
          show: false,
          message: "Password required",
        },
      },
      checkboxVal: false,
      snackbar: {
        isShow: false,
        content: "",
      },
      loading: false,
    };
  },
  methods: {
    validate(ref) {
      this.errorObj = {};
      let isError = false;
      try {
        this.fromData.forEach((item) => {
          // 提交之前先将每个input初始化字段init,show设为false，要不无法触发默认错误提示
          this.$refs[item.key][0].init = false;
          if (item.reg && !item.reg.test(item.value)) {
            this.errorObj[item.key] = {
              show: true,
              message: item.errorText,
            };
            isError = true;
            // 只要有一个不符合条件就终止循环,跳出循环体
            throw Error();
          }
        });
      } catch (error) {}
      return !isError;
    },
    handleLogin(e) {
      if (this.validate()) {
        this.fromData.forEach((item) => {
          if (item.key === "formLogin") {
            this.query.account = item.value;
          }
          if (item.key === "formPassword") {
            this.query.password = item.value;
          }
        });
        this.loading = true;
        Ajax.post("/login-service/login", this.query)
          .then((res) => {
            this.loading = false;
            if (res.code === "0000") {
				if(res.data.userType==3){
					this.snackbar = {
					  isShow: true,
					  content: "Invalid email address or incorrect password.",
					};
					return false
				}
              if (this.checkboxVal) {
                this.fromData.forEach((item) => {
                  localStorage.setItem(item.key, item.value);
                });
              } else {
                this.fromData.forEach((item) => {
                  localStorage.removeItem(item.key);
                });
              }

              //if (
               // res.data.isBackPermsion !== "1" ||
               // res.data.status !== "active"
             // ) {
              //  this.snackbar = {
               //   isShow: true,
                //  content: "No permission or closed",
               // };
               // return false;
             // }

              // isadmin 废弃，后续删除
              this.$store.commit("SET_ISADMIN", res.data.isAdmin);

              // 登录后保存当前用户的userid,即company列表里的userid
              this.$store.commit("SET_USERID", res.data.userId);
              this.$store.commit("SET_USERTYPE", res.data.userType);
              this.$store.commit("SET_TOKEN", res.data.token);
              this.$store.commit("SET_ROLEPERMISSION", res.data.rolePermission);
              this.getUserInfo(res.data.userId);

              // login标识
              sessionStorage.setItem("login", true);
              this.$router.push("/dashBoard");
            } else {
              this.snackbar = {
                isShow: true,
                content: res.message,
              };
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    getUserInfo(userId) {
      Ajax.get("/usermanage/user/selectUserInfoById", {
        id: userId,
      }).then((res) => {
        let userInfo = { ...res.data };
        this.$store.commit("SET_USER_INFO", userInfo);
      });
    },
  },
  created() {
    this.fromData.forEach((item) => {
      if (localStorage.getItem(item.key)) {
        this.checkboxVal = true;
        item.value = localStorage.getItem(item.key) || "";
      }
    });
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.page-login {
  width: 1424px;
  height: 584px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/images/login_bg.png") no-repeat 50% 100%;
  overflow: hidden;
  .wrap {
    display: flex;
    width: 800px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden;
    .wrap_left {
      flex: 1 1 50%;
      height: 100%;
      background: #255180;
      padding: 50px 32px;
      text-align: left;

      h2 {
        font-family: OpenSans-Bold;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 17px;
        line-height: 45px;
      }
      p {
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        margin-bottom: 58px;
      }
      .img {
        padding: 0 16px;
      }
    }
    .wrap_right {
      flex: 1 1 50%;
      padding: 50px 32px;
      text-align: left;
      background: #fff;
      .title {
        height: 43px;
        line-height: 43px;
        font-family: OpenSans-Bold;
        color: #202124;
        font-size: 32px;
        margin-bottom: 100px;
      }
      .form {
        .password {
          margin-bottom: 5px;
        }
        .forgot {
          text-align: right;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 500;
          color: #1a73e8;
        }
      }
      .bottom {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .remember {
          float: left;
        }
        .submit {
          float: right;
          width: 64px;
          height: 32px;
          background: #1a73e8;
          color: #fff;
          font-family: OpenSans-Bold;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
